// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import './App.css';

import Home from './pages';
// import Test from  './pages/test';
// import Wallet from  './pages/wallet';
// import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className='App'>
      <Home />
      {/* <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/test' element={<Test />}></Route>
          <Route path='/wallet' element={<Wallet />}></Route>
        </Routes>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
