import React, { useState, useEffect } from 'react';

import LazyLoad from 'react-lazyload';

const Home = () => {
  const [loader, setLoader] = useState(false);

  
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
      console.log('This will run after 1 second!');
    }, 3000);
  });
  return loader ? (
    // <img src='loader.gif' />
    <></>
  ) : (
    <>
      <section className='banner-section'>
        <div className='banner-container'>
          <div className='head-icon'>
            <LazyLoad>
              <img src='images/logo-icon.webp' alt='icon' />
            </LazyLoad>
          </div>
          <div className='banner-cartoon-img'>
            <LazyLoad>
              <img src='images/carton-img1.webp' alt='morph' />
            </LazyLoad>
          </div>
          <div className='banner-content'>
            <div className='banner-logo'>
              <span>
                <LazyLoad>
                  <img src='images/morph-logo.webp' alt='logo' />
                </LazyLoad>
              </span>
            </div>
            <div className='content-detail'>
              <div className='banner-title'>
                <LazyLoad>
                  <img src='images/banner-text-bg.webp' alt='morph' />
                </LazyLoad>
                <h1> a Web3 phigital brand</h1>
              </div>
              <div className='content-discriptions'>
                <p>
                  morph is an NFT collection of 1000 charming creatures. <br /> Clumsy, Endearing and adorable each of them has their own personality.
                </p>
                <p>Bridging the doors between virtual and physical worlds, morph is a community-driven Web3 brand centered around collaborations.</p>
                <p>
                  Created in The Lab, the morph’s are here <br /> to bring back magic into your life.
                </p>
              </div>
            </div>
          </div>
          <div className='banner-enter-text'>
            <div className='text-hand-title'>
              <LazyLoad>
                <img src='images/text-1.webp' alt='morph' />
              </LazyLoad>
            </div>
            <div className='text-hand-image'>
              <LazyLoad>
                <img src='images/hand-img.webp' alt='morph' />
              </LazyLoad>
            </div>
          </div>
          <div className='morph-section'>
            <div className='morph-title'>
              <LazyLoad>
                <img src='images/text-2.webp' alt='morph' />
              </LazyLoad>
            </div>
            <div className='morph-circle'>
              <LazyLoad>
                <img src='images/icon1.webp' alt='images' />
              </LazyLoad>
            </div>
            <div className='morph-cards'>
              <div className='morph-card-holder'>
                <div className='card-image'>
                  <LazyLoad>
                    <img src='images/cartoon-card1.webp' alt='morph' />
                  </LazyLoad>
                </div>
                <div className='card-content'>
                  <div className='card-discriptions'>
                    <p>
                      Owning a morph is the unique way that gives you life-time membership access to The Lab. Being part of it allows you to access exclusive
                      phygital NFT drops in partnership with famous artistic directors.
                    </p>
                  </div>
                  <div className='card-discriptions'>
                    <p>
                      Lab members will get exclusive claims & WL on all drops. Combining virtual and physical versions ​​through a burn system, each piece of
                      art is a unique limited-edition.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className='morph-card-holder'>
                <div className='card-image'>
                  <LazyLoad>
                    <img src='images/cartoon-card2.webp' alt='morph' />
                  </LazyLoad>
                </div>
                <div className='card-content'>
                  <div className='card-discriptions'>
                    <p>
                      The Lab : Owning a morph is the unique way that give you membership access to The Lab. Being part of The Lab allows you to access
                      exclusive NFT drops with luxury & streetwear famous artistic directors, much more that will be revealed over time.
                    </p>
                  </div>
                </div>
              </div>
              <div className='morph-card-holder'>
                <div className='card-image'>
                  <LazyLoad>
                    <img src='images/cartoon-card3.webp' alt='morph' />
                  </LazyLoad>
                </div>
                <div className='card-content'>
                  <div className='card-discriptions'>
                    <p>
                      As you, the morph’s love to party! That’s why crazy live parties/events that you'll remember all your life will be organized… The lucky
                      ones who will be physically present will not only have fun (lot of fun) and meet people (lot of people)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='banner-bg-cartoon'>
              <LazyLoad height={200}>
                <img src='images/carton-img2.webp' alt='img' />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
